import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { SettingsIconRef, SettingsIconProps } from './SettingsIcon.types';

export const SettingsIconRoot = styled(IconBase)``;

export const settingsIconClassNames = {
  root: SettingsIconRoot.toString().slice(1),
};

export const SettingsIcon = React.forwardRef<
  SettingsIconRef,
  SettingsIconProps
>(function SettingsIcon({ className, style, size, color, ...otherProps }, ref) {
  return (
    <SettingsIconRoot
      data-testid="SettingsIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M3.66667 6.33333L3 8L1 8.66667V11.3333L3 12L3.66667 13.6667L2.66667 15.3333L4.66667 17.3333L6.33333 16.3333L8 17L8.66667 19H11.3333L12 17L13.6667 16.3333L15.3333 17.3333L17.3333 15.3333L16.3333 13.6667L17 12L19 11.3333V8.66667L17 8L16.3333 6.33333L17.3333 4.66667L15.3333 2.66667L13.6667 3.66667L12 3L11.3333 1H8.66667L8 3L6.33333 3.66667L4.66667 2.66667L2.66667 4.66667L3.66667 6.33333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <circle cx="10" cy="10" r="4" stroke="currentColor" />
    </SettingsIconRoot>
  );
});
