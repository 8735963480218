import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { MoreCircleIconRef, MoreCircleIconProps } from './MoreCircleIcon.types';

export const MoreCircleIconRoot = styled(IconBase)``;

export const moreCircleIconClassNames = {
  root: MoreCircleIconRoot.toString().slice(1),
};

export const MoreCircleIcon = React.forwardRef<
  MoreCircleIconRef,
  MoreCircleIconProps
>(function MoreCircleIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <MoreCircleIconRoot
      data-testid="MoreCircleIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke="currentColor"
      />
      <circle r="1" cx="5" cy="10" fill="currentColor" />
      <circle r="1" cx="10" cy="10" fill="currentColor" />
      <circle r="1" cx="15" cy="10" fill="currentColor" />
    </MoreCircleIconRoot>
  );
});
