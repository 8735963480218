import React from 'react';
import styled from 'styled-components';

import { BadgeRef, BadgeProps } from './Badge.types';

const anchorOriginBottomLeftClassName = 'Badge-anchorOriginBottomLeft';
const anchorOriginBottomCenterClassName = 'Badge-anchorOriginBottomCenter';
const anchorOriginBottomRightClassName = 'Badge-anchorOriginBottomRight';
const anchorOriginMiddleLeftClassName = 'Badge-anchorOriginMiddleLeft';
const anchorOriginMiddleCenterClassName = 'Badge-anchorOriginMiddleCenter';
const anchorOriginMiddleRightClassName = 'Badge-anchorOriginMiddleRight';
const anchorOriginTopLeftClassName = 'Badge-anchorOriginTopLeft';
const anchorOriginTopCenterClassName = 'Badge-anchorOriginTopCenter';
const anchorOriginTopRightClassName = 'Badge-anchorOriginTopRight';

export const badgeMapAnchorOriginToBadgeClassName = {
  bottom: {
    left: anchorOriginBottomLeftClassName,
    center: anchorOriginBottomCenterClassName,
    right: anchorOriginBottomRightClassName,
  },
  middle: {
    left: anchorOriginMiddleLeftClassName,
    center: anchorOriginMiddleCenterClassName,
    right: anchorOriginMiddleRightClassName,
  },
  top: {
    left: anchorOriginTopLeftClassName,
    center: anchorOriginTopCenterClassName,
    right: anchorOriginTopRightClassName,
  },
};

export const BadgeRoot = styled.span`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
`;

export const BadgeBadge = styled.span`
  position: absolute;
  z-index: 1;

  ${({ theme }) => theme.fns.getTypographyStyles('primary.r12')}

  .${anchorOriginBottomLeftClassName} {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }

  .${anchorOriginBottomCenterClassName} {
    bottom: 0;
    left: 0;
    margin-left: 50%;
    transform: translate(-50%, 100%);
  }

  .${anchorOriginBottomRightClassName} {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
  }

  .${anchorOriginMiddleLeftClassName} {
    left: 0;
    top: 0;
    margin-top: 50%;
    transform: translate(-100%, -50%);
  }

  .${anchorOriginMiddleCenterClassName} {
    top: 0;
    left: 0;
    margin-left: 50%;
    margin-top: 50%;
    transform: translate(-50%, -50%);
  }

  .${anchorOriginMiddleRightClassName} {
    top: 0;
    right: 0;
    margin-top: 50%;
    transform: translate(100%, -50%);
  }

  .${anchorOriginTopLeftClassName} {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .${anchorOriginTopCenterClassName} {
    top: 0;
    left: 0;
    margin-left: 50%;
    transform: translate(-50%, -100%);
  }

  .${anchorOriginTopRightClassName} {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

export const badgeClassNames = {
  root: BadgeRoot.toString().slice(1),
  badge: BadgeBadge.toString().slice(1),
  anchorOriginBottomLeft: anchorOriginBottomLeftClassName,
  anchorOriginBottomCenter: anchorOriginBottomCenterClassName,
  anchorOriginBottomRight: anchorOriginBottomRightClassName,
  anchorOriginMiddleLeft: anchorOriginMiddleLeftClassName,
  anchorOriginMiddleCenter: anchorOriginMiddleCenterClassName,
  anchorOriginMiddleRight: anchorOriginMiddleRightClassName,
  anchorOriginTopLeft: anchorOriginTopLeftClassName,
  anchorOriginTopCenter: anchorOriginTopCenterClassName,
  anchorOriginTopRight: anchorOriginTopRightClassName,
};

export function defaultFormatter(
  badgeContent: BadgeProps['badgeContent'],
): string {
  if (badgeContent <= 999) {
    return `${badgeContent}`;
  }

  if (badgeContent <= 999999) {
    const cappedContent = Math.round(badgeContent / 1000);
    return `${cappedContent}k`;
  }

  if (badgeContent <= 999999999) {
    const cappedContent = Math.round(badgeContent / 1000000);
    return `${cappedContent}M`;
  }

  if (badgeContent > 999999999) {
    return '999M+';
  }

  return '0';
}

export const Badge = React.forwardRef<BadgeRef, BadgeProps>(function Badge(
  {
    className,
    style,
    badgeContent,
    anchorOrigin = {
      horizontal: 'center',
      vertical: 'bottom',
    },
    children,
    format = defaultFormatter,
  },
  ref,
) {
  const { vertical, horizontal } = anchorOrigin;
  const formattedContent = format(badgeContent);

  return (
    <BadgeRoot
      data-testid="Badge"
      ref={ref}
      className={className}
      style={style}
    >
      {children}
      <BadgeBadge
        className={badgeMapAnchorOriginToBadgeClassName[vertical][horizontal]}
      >
        {formattedContent}
      </BadgeBadge>
    </BadgeRoot>
  );
});
