import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  ChevronDownThinIconRef,
  ChevronDownThinIconProps,
} from './ChevronDownThinIcon.types';

export const ChevronDownThinIconRoot = styled(IconBase)``;

export const chevronDownThinIconClassNames = {
  root: ChevronDownThinIconRoot.toString().slice(1),
};

export const ChevronDownThinIcon = React.forwardRef<
  ChevronDownThinIconRef,
  ChevronDownThinIconProps
>(function ChevronDownThinIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <ChevronDownThinIconRoot
      data-testid="ChevronDownThinIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M1 6L10 15L19 6"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ChevronDownThinIconRoot>
  );
});
