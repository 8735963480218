import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { FacebookIconRef, FacebookIconProps } from './FacebookIcon.types';

export const FacebookIconRoot = styled(IconBase)``;

export const facebookIconClassNames = {
  root: FacebookIconRoot.toString().slice(1),
};

export const FacebookIcon = React.forwardRef<
  FacebookIconRef,
  FacebookIconProps
>(function FacebookIcon({ className, style, size, color, ...otherProps }, ref) {
  return (
    <FacebookIconRoot
      data-testid="FacebookIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M18.8967 0H1.10333C0.494167 0 0 0.494167 0 1.10333V18.8967C0 19.5067 0.494167 20 1.10333 20H10.6817V12.255H8.07417V9.2375H10.6817V7.00833C10.6817 4.42583 12.26 3.02083 14.5642 3.02083C15.6683 3.02083 16.6175 3.10167 16.8942 3.13833V5.83833H15.2933C14.0433 5.83833 13.8 6.43917 13.8 7.31417V9.24H16.7867L16.3992 12.265H13.8V20H18.8958C19.5067 20 20 19.5067 20 18.8967V1.10333C20 0.494167 19.5067 0 18.8967 0Z"
        fill="currentColor"
      />
    </FacebookIconRoot>
  );
});
