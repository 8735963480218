import { useState, useEffect } from 'react';

export const portalRootId = 'Portal';

export const createPortalRootEl = (): HTMLElement => {
  const portalRootEl = document.createElement('div');

  portalRootEl.id = portalRootId;
  portalRootEl.dataset.testid = 'Portal';
  document.body.appendChild(portalRootEl);

  return portalRootEl;
};

export const getPortalRootEl = (): HTMLElement | null => {
  // When SSRing, return null.
  if (typeof window === 'undefined') return null;

  const portalRootEl = document.getElementById(portalRootId);

  // If a portal root element already exists, return it.
  if (portalRootEl) {
    return portalRootEl;
  }

  // Otherwise, create, append and return a new portal root element.
  return createPortalRootEl();
};

export const cleanPortalRootEl = () => {
  const portalRootEl = document.getElementById(portalRootId);

  // Remove the portal root element if not used by any other portal instance.
  if (portalRootEl && portalRootEl.childElementCount < 2) {
    document.body.removeChild(portalRootEl);
  }
};

export const usePortalRoot = () => {
  const [portalRoot] = useState(() => getPortalRootEl());

  useEffect(() => {
    return cleanPortalRootEl;
  }, []);

  return portalRoot;
};
