import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  ArrowDownwardIconRef,
  ArrowDownwardIconProps,
} from './ArrowDownwardIcon.types';

export const ArrowDownwardIconRoot = styled(IconBase)``;

export const arrowDownwardIconClassNames = {
  root: ArrowDownwardIconRoot.toString().slice(1),
};

export const ArrowDownwardIcon = React.forwardRef<
  ArrowDownwardIconRef,
  ArrowDownwardIconProps
>(function ArrowDownwardIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <ArrowDownwardIconRoot
      data-testid="ArrowDownwardIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M9.88891 2.00006L9.88891 18M9.88891 18L17 11.1765M9.88891 18L2.77783 11.1765"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </ArrowDownwardIconRoot>
  );
});
