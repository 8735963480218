import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { ThumbDownIconRef, ThumbDownIconProps } from './ThumbDownIcon.types';

export const ThumbDownIconRoot = styled(IconBase)``;

export const thumbDownIconClassNames = {
  root: ThumbDownIconRoot.toString().slice(1),
};

export const ThumbDownIcon = React.forwardRef<
  ThumbDownIconRef,
  ThumbDownIconProps
>(function ThumbDownIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <ThumbDownIconRoot
      data-testid="ThumbDownIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M6.06567 3.72625L6.02976 10.6119C6.36879 10.6565 8.20344 10.9855 9.17233 12.9759L9.17248 12.9763C9.38105 13.4055 9.54719 14.025 9.69898 14.6611C9.76022 14.9178 9.81933 15.1781 9.87872 15.4397C9.97305 15.8552 10.0681 16.2739 10.1736 16.686C10.3445 17.3542 10.5269 17.9366 10.7378 18.3483C10.9672 18.7963 11.1331 18.857 11.1887 18.8598L11.1893 18.8598C11.5679 18.8792 11.8635 18.707 12.1147 18.3264C12.3786 17.9265 12.5616 17.332 12.6554 16.6389C12.748 15.954 12.7477 15.2161 12.6774 14.5634C12.6058 13.8981 12.4669 13.373 12.3153 13.0864L11.9495 12.3949L12.7307 12.3533C12.8649 12.3461 13.1575 12.3336 13.5369 12.3174C14.073 12.2945 14.7826 12.2642 15.4638 12.2311C16.0516 12.2025 16.6238 12.1716 17.0639 12.141C17.2846 12.1257 17.4676 12.1107 17.6011 12.0965C17.6684 12.0893 17.7177 12.083 17.7506 12.0777C17.7536 12.0772 17.7563 12.0767 17.7588 12.0763L17.851 12.0363C17.9219 11.9826 18.017 11.8988 18.1134 11.7903C18.3412 11.5338 18.5088 11.2186 18.4994 10.9058L18.4994 10.9045C18.4888 10.5181 18.195 10.1454 17.8123 9.83708L17.3775 9.48674L17.7735 9.09311C17.9125 8.95494 18.0625 8.73476 18.1574 8.38256L18.1577 8.38146C18.2292 8.11809 18.1675 7.91705 18.0325 7.73436C17.8839 7.53327 17.647 7.36138 17.401 7.22786L16.9219 6.96784L17.2222 6.51291C17.3317 6.34716 17.4249 6.1558 17.4682 5.94314C17.4887 5.84133 17.4603 5.7095 17.3149 5.52847C17.169 5.34667 16.9506 5.17612 16.7322 5.03607L16.3198 4.7716L16.5758 4.35388C16.6749 4.19231 16.7738 3.97039 16.826 3.66892L16.8261 3.66836C16.8907 3.29732 16.7453 3.02167 16.5493 2.81154C16.45 2.70502 16.3435 2.62309 16.2604 2.5676C16.2195 2.54026 16.186 2.52041 16.1641 2.50815C16.1592 2.50538 16.155 2.50308 16.1514 2.50113L16.1472 2.50128L7.45861 2.81888L6.06568 3.72351L6.06567 3.72625ZM17.7787 12.0725C17.7786 12.0725 17.7776 12.0727 17.776 12.0731C17.7779 12.0727 17.7787 12.0724 17.7787 12.0725ZM7.47391 2.80895L7.47379 2.80902C7.47387 2.80898 7.47394 2.80893 7.47401 2.80888L7.47391 2.80895Z"
        stroke="currentColor"
      />
      <path
        d="M1.5 3.95636L1.5 10.4962L3.53385 10.4962L3.53385 3.95636L1.5 3.95636Z"
        stroke="currentColor"
      />
    </ThumbDownIconRoot>
  );
});
