import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { MoveUpIconRef, MoveUpIconProps } from './MoveUpIcon.types';

export const MoveUpIconRoot = styled(IconBase)``;

export const moveUpIconClassNames = {
  root: MoveUpIconRoot.toString().slice(1),
};

export const MoveUpIcon = React.forwardRef<MoveUpIconRef, MoveUpIconProps>(
  function MoveUpIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <MoveUpIconRoot
        data-testid="MoveUpIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M2.5 10.4312L10 1L17.5 10.4312H14V19H6V10.4312H2.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </MoveUpIconRoot>
    );
  },
);
