import React from 'react';
import ReactDOM from 'react-dom';

import { usePortalRoot } from './usePortalRoot.hook';
import { PortalProps } from './Portal.types';

// Portal instances won't render (or will render `null`) when on SSR.
// We've assumed Portal is only ever going to be used by components that usually
// need to be outside its parent node so that they can render without being
// affected by `overflow: hidden` CSS rules, etc (e.g. tooltips, modals,
// drawers).
// If we ever want a Portal component that renders on SSR too, we can draw
// inspiration from
// https://michalzalecki.com/render-react-portals-on-the-server/#the-third-try
export const Portal: React.FC<PortalProps> = ({ children }) => {
  // This hooks returns the DOM node in which portal instances should append
  // their contents or `null` otherwise.
  const portalRoot = usePortalRoot();

  if (portalRoot) {
    return ReactDOM.createPortal(children, portalRoot);
  }

  // When SSRing, return null.
  return null;
};
