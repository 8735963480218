import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { MoveDownIconRef, MoveDownIconProps } from './MoveDownIcon.types';

export const MoveDownIconRoot = styled(IconBase)``;

export const moveDownIconClassNames = {
  root: MoveDownIconRoot.toString().slice(1),
};

export const MoveDownIcon = React.forwardRef<
  MoveDownIconRef,
  MoveDownIconProps
>(function MoveDownIcon({ className, style, size, color, ...otherProps }, ref) {
  return (
    <MoveDownIconRoot
      data-testid="MoveDownIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M2.5 9.56884L10 19L17.5 9.56884H14V1H6V9.56884H2.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </MoveDownIconRoot>
  );
});
