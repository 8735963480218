import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { BackIconRef, BackIconProps } from './BackIcon.types';

export const BackIconRoot = styled(IconBase)``;

export const backIconClassNames = {
  root: BackIconRoot.toString().slice(1),
};

export const BackIcon = React.forwardRef<BackIconRef, BackIconProps>(
  function BackIcon({ className, style, size }, ref) {
    return (
      <BackIconRoot
        data-testid="BackIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
      >
        <path
          d="M18.8236 10H1.17651M1.17651 10L8.23534 2.94116M1.17651 10L8.23534 17.0588"
          strokeWidth="1.17647"
          strokeLinecap="round"
          stroke="currentColor"
        />
      </BackIconRoot>
    );
  },
);
