import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { ThumbUpIconRef, ThumbUpIconProps } from './ThumbUpIcon.types';

export const ThumbUpIconRoot = styled(IconBase)``;

export const thumbUpIconClassNames = {
  root: ThumbUpIconRoot.toString().slice(1),
};

export const ThumbUpIcon = React.forwardRef<ThumbUpIconRef, ThumbUpIconProps>(
  function ThumbUpIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <ThumbUpIconRoot
        data-testid="ThumbUpIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M6.06637 16.3025L6.03055 9.57705C6.37068 9.53301 8.20177 9.21017 9.17106 7.26035L9.17121 7.26003C9.38068 6.83792 9.54721 6.22957 9.69896 5.60681C9.76031 5.35504 9.81946 5.09996 9.87886 4.84376C9.97311 4.43722 10.068 4.02788 10.1734 3.62453C10.3443 2.97058 10.5263 2.40194 10.7363 2.00042C10.9635 1.56603 11.1284 1.50443 11.1886 1.5015L11.1893 1.50147C11.5731 1.48229 11.8689 1.65383 12.1178 2.02306C12.3801 2.41225 12.5624 2.99152 12.6559 3.66842C12.7483 4.33714 12.748 5.05794 12.6779 5.6955C12.6064 6.34565 12.468 6.85715 12.3177 7.13518L11.9418 7.83096L12.7316 7.87217C12.8659 7.87918 13.1583 7.89141 13.5376 7.90728C14.0737 7.9297 14.7832 7.95938 15.4646 7.99181C16.0524 8.01978 16.6245 8.05 17.0648 8.07999C17.2855 8.09502 17.4686 8.1097 17.6023 8.12362C17.6697 8.13062 17.7192 8.1369 17.7523 8.14214C17.7558 8.14268 17.7589 8.14319 17.7616 8.14365L17.855 8.1833C17.9259 8.23581 18.0208 8.31779 18.1169 8.42378C18.3449 8.67505 18.5087 8.97977 18.4995 9.27834L18.4994 9.27967C18.4891 9.64884 18.2009 10.0107 17.8164 10.314L17.3688 10.6672L17.7774 11.0649C17.9157 11.1995 18.0642 11.4132 18.1581 11.7548L18.1584 11.7559C18.228 12.0063 18.169 12.1972 18.0356 12.3739C17.8879 12.5696 17.6516 12.7378 17.405 12.8688L16.9146 13.1294L17.2251 13.5899C17.334 13.7514 17.426 13.9369 17.4687 14.1419C17.4874 14.2329 17.4634 14.3565 17.3183 14.5335C17.173 14.7106 16.9551 14.8775 16.7364 15.0148L16.3135 15.2803L16.5785 15.7035C16.6767 15.8605 16.7748 16.0758 16.8264 16.3682L16.8265 16.3688C16.8896 16.7231 16.7487 16.9878 16.5531 17.1932C16.4541 17.2972 16.3478 17.3773 16.2647 17.4317C16.2237 17.4585 16.1901 17.4779 16.1682 17.49C16.1625 17.4931 16.1578 17.4957 16.1538 17.4978L16.147 17.4975L7.45651 17.1865L6.06637 16.3025ZM17.7808 8.1473C17.7807 8.1473 17.7799 8.14712 17.7783 8.14674L17.7808 8.1473ZM16.1403 17.5047C16.1399 17.5049 16.1396 17.505 16.1393 17.5052L16.1393 17.5051C16.1396 17.505 16.1399 17.5048 16.1403 17.5047ZM7.47064 17.1954L7.47053 17.1954L7.47064 17.1954Z"
          stroke="currentColor"
        />
        <path
          d="M1.5 16.0734V9.69104H3.5337V16.0734H1.5Z"
          stroke="currentColor"
        />
      </ThumbUpIconRoot>
    );
  },
);
