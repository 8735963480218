import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { MenuIconRef, MenuIconProps } from './MenuIcon.types';

export const MenuIconRoot = styled(IconBase)``;

export const menuIconClassNames = {
  root: MenuIconRoot.toString().slice(1),
};

export const MenuIcon = React.forwardRef<MenuIconRef, MenuIconProps>(
  function MenuIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <MenuIconRoot
        data-testid="MenuIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <line
          x1="0.75"
          y1="-0.75"
          x2="15.9167"
          y2="-0.75"
          transform="matrix(1 0 0 -1 1.66675 3.33334)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <line
          x1="2.41675"
          y1="10.0833"
          x2="17.5834"
          y2="10.0833"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <line
          x1="2.41675"
          y1="15.9167"
          x2="17.5834"
          y2="15.9167"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </MenuIconRoot>
    );
  },
);
