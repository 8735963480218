import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  AddItemManuallyIconRef,
  AddItemManuallyIconProps,
} from './AddItemManuallyIcon.types';

export const AddItemManuallyIconRoot = styled(IconBase)``;

export const AddItemManuallyIconClassNames = {
  root: AddItemManuallyIconRoot.toString().slice(1),
};

export const AddItemManuallyIcon = React.forwardRef<
  AddItemManuallyIconRef,
  AddItemManuallyIconProps
>(function AddItemManuallyIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <AddItemManuallyIconRoot
      data-testid="AddItemManuallyIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M11.7755 3.00016H12.6645C13.5027 3.00016 13.9217 3.00016 14.1822 3.26381C14.4425 3.52746 14.4425 3.95179 14.4425 4.80046V8.00098M11.7755 3.00016V3.80029C11.7755 4.22462 11.7755 4.4368 11.6453 4.56862C11.515 4.70044 11.3055 4.70044 10.8864 4.70044H5.55223C5.13313 4.70044 4.92359 4.70044 4.79339 4.56862C4.6632 4.4368 4.6632 4.22462 4.6632 3.80029V3.00016M11.7755 3.00016C11.7755 2.57583 11.7755 2.26364 11.6453 2.13182C11.515 2 11.3055 2 10.8864 2H5.55223C5.13313 2 4.92359 2 4.79339 2.13182C4.6632 2.26364 4.6632 2.57583 4.6632 3.00016M4.6632 3.00016H3.77416C2.93598 3.00016 2.51688 3.00016 2.25649 3.26381C1.99609 3.52746 1.99609 3.95179 1.99609 4.80046V15.198C1.99609 16.0468 1.99609 16.4711 2.25649 16.7347C2.51688 16.9983 2.93598 16.9983 3.77416 16.9983H7.77481M11.9977 18.0026H15.9984C16.9413 18.0026 17.4128 18.0026 17.7058 17.7097C17.9987 17.4167 17.9987 16.9452 17.9987 16.0023V12.0016C17.9987 11.0587 17.9987 10.5872 17.7058 10.2943C17.4128 10.0013 16.9413 10.0013 15.9984 10.0013H11.9977C11.0548 10.0013 10.5833 10.0013 10.2903 10.2943C9.9974 10.5872 9.9974 11.0587 9.9974 12.0016V16.0023C9.9974 16.9452 9.9974 17.4167 10.2903 17.7097C10.5833 18.0026 11.0548 18.0026 11.9977 18.0026Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </AddItemManuallyIconRoot>
  );
});
